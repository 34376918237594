<template>
  <div>
    <div class="mb-6 d-flex align-center ">
      <span class="search-title pr-4">Поиск</span>
      <form @submit.prevent="fetchPromotions()">
        <VTextField
          v-model="promotionsFilter.headerTitle"
          append-icon="mdi-magnify"
          background-color="#F2F2F2"
          class="search-input"
          color="black"
          dense
          hide-details
          outlined
          placeholder="Введите название акции..."
        />
      </form>
      <VCheckbox
        v-model="promotionsFilter.activePromotions"
        class="my-auto ml-4"
        color="success"
        hide-details
        label="показывать активные"
      />
    </div>
    <VSimpleTable class="elevation-2">
      <thead>
        <tr class="titles-thead">
          <th class="text-left city-column">
            <span>Город</span>
          </th>
          <th class="text-left">
            <span>Название заведения</span>
          </th>
          <th class="text-left">
            <span>Название акции</span>
          </th>
          <th class="text-left">
            <span>Тип</span>
          </th>
          <th class="text-center delivery-service-column">
            <span>Служба доставки</span>
          </th>
          <th class="text-left">
            <span>Статус</span>
          </th>
          <th></th>
        </tr>
        <tr class="search-thead">
          <th>
            <VSelect
              v-model="promotionsFilter.cities"
              background-color="#5B5B5B"
              class="cities__select"
              clearable
              dark
              dense
              hide-details
              item-text="title"
              item-value="pk"
              :items="cities"
              label="Все"
              multiple
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
            >
              <template #selection="{ item, index }">
                <span v-if="index < 1">
                  {{ item.title }}
                </span>
                <span v-if="index === 1" class="pl-1">
                  + {{ promotionsFilter.cities.length - 1 }}
                </span>
              </template>
            </VSelect>
          </th>
          <th>
            <VTextField
              v-model="promotionsFilter.restaurantTitle"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
            />
          </th>
          <th>
            <VTextField
              v-model="promotionsFilter.headerTitle"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              outlined
              prepend-inner-icon="mdi-magnify"
              solo
            />
          </th>
          <th></th>
          <th>
            <VSelect
              v-model="promotionsFilter.deliveryService"
              background-color="#5B5B5B"
              clearable
              dark
              dense
              hide-details
              :items="deliveryServiceChoices"
              label="Все"
              outlined
              solo
            />
          </th>
          <th></th>
          <th></th>
        </tr>
      </thead>
      <tbody>
        <tr v-for="promotion in filteredPromotions" :key="promotion.pk">
          <td>{{ getCityTitleByLocation(promotion.restaurant.location.coordinate) }}</td>
          <td>{{ promotion.restaurant.title }}</td>
          <td>{{ promotion.header }}</td>
          <td>{{ promotion.typeDescription }}</td>
          <td>{{ promotion.restaurant.deliveryService }}</td>
          <td>
            <span v-if="promotion.isActive" class="state-title-active">Активно</span>
            <span v-else class="state-title-inactive">Не активно</span>
          </td>
          <td>
            <VBtn
              class="ml-3"
              icon
              small
              @click="editPromotion(promotion)"
            >
              <VIcon>mdi-chevron-right</VIcon>
            </VBtn>
          </td>
        </tr>
      </tbody>
    </VSimpleTable>
    <div v-if="filteredPromotions.length" class="d-flex my-6">
      <VBtn
        class="ml-auto mr-2 text-white"
        color="green"
        dark
        :disabled="currentPage <= 1"
        @click="switchPage(currentPage - 1)"
      >
        <VIcon dark>
          mdi-chevron-left
        </VIcon>
      </VBtn>
      <VBtn
        class="mr-0 ml-2"
        color="green"
        dark
        :disabled="filteredPromotions.length < promotionsFilter.defaultLimit"
        @click="switchPage(currentPage + 1)"
      >
        <VIcon>
          mdi-chevron-right
        </VIcon>
      </VBtn>
    </div>
    <VAlert v-else type="info">
      Нет акций для отображения
    </VAlert>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex';
import { fetchPromotions, fetchRestaurantChoices } from '@/api/api';
import { eventHub, events } from '@/global/eventhub';

export default {
  name: 'PromotionsTable',
  emits: ['editPromotion'],
  data() {
    return {
      promotions: [],
      deliveryServiceChoices: [],
      promotionsFilter: {
        cities: '',
        restaurantTitle: '',
        headerTitle: '',
        deliveryService: '',
        defaultLimit: 25,
        activePromotions: true,
      },
      currentPage: 1,
    };
  },
  computed: {
    ...mapGetters(['cities']),
    filteredPromotions() {
      let promotions = this.promotions;
      const filter = this.promotionsFilter;

      const filterByCityTitle = filter.cityTitle && filter.cityTitle.toLowerCase();
      if (filterByCityTitle) {
        promotions = promotions.filter(
          (promotion) => this.getCityTitleByLocation(promotion.restaurant.location)
            .toLowerCase()
            .includes(filterByCityTitle),
        );
      }
      if (filter.deliveryService) {
        promotions = promotions.filter(
          (promotion) => promotion.restaurant.deliveryService === filter.deliveryService,
        );
      }
      return promotions;
    },
  },
  methods: {
    ...mapActions(['getCities']),
    getCityTitleByLocation(location) {
      return this.cities.reduce((init, city) => {
        const contains = city.containsPoint(location.longitude, location.latitude);
        return contains ? city.title : init;
      }, null);
    },
    fetchPromotions() {
      fetchPromotions(
        this.promotionsFilter,
        this.currentPage,
      )
        .then((promotions) => {
          this.promotions = promotions;
        });
    },
    editPromotion(promotion) {
      this.$emit('editPromotion', promotion);
    },
    switchPage(selectedPage) {
      this.currentPage = selectedPage;
      this.fetchPromotions();
    },
  },
  created() {
    this.getCities();
    fetchRestaurantChoices().then((choices) => {
      this.deliveryServiceChoices = choices.deliveryChoices;
    });
    this.fetchPromotions();
    eventHub.$on(events.promotionCreated, this.fetchPromotions);
    eventHub.$on(events.promotionUpdated, this.fetchPromotions);
  },
  watch: {
    promotionsFilter: {
      deep: true,
      handler() {
        this.currentPage = 1;
        this.fetchPromotions();
      },
    },
  },
};
</script>

<style scoped lang='scss'>
@import 'src/assets/scss/mixin.scss';

.search-title {
  color: $default_dark_gray_color;
  font-family: $default_font;
  font-weight: 600;
  font-size: 17px;
  line-height: 16px;
}
.search-input {
  width: 400px;
}
.titles-thead th {
  color: $default_dark_gray_color !important;
  background-color: white;
}
.search-thead th {
  color: white !important;
  background-color: $default_gray_color;
}
a {
  text-decoration: none;
}
.city-column {
  min-width: 180px;
  max-width: 230px;
}
.delivery-service-column {
  width: 200px;
}
.state-title-active {
  color: #4CAF50;
}
.state-title-inactive {
  color: #9B9B9B;
}
</style>
