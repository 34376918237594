<template>
  <VCard class="ma-7" flat>
    <div class="d-flex mb-2">
      <span class="page_title">Акции</span>
      <div v-if="state === 'LIST'" class="pl-8">
        <VBtn
          class="default-btn white--text"
          color="#4caf50"
          rounded
          @click="goToCreating"
        >
          <VIcon left>
            mdi-plus-circle
          </VIcon>
          Создать акцию
        </VBtn>
      </div>
    </div>
    <PromotionEdit
      v-if="state === 'CREATING' || state === 'EDITING'"
      class="pb-7"
      :promotion="editedPromotion"
      :targets="targets"
      @save="saveEditedPromotion"
    />
    <PromotionsTable class="mt-4" @editPromotion="goToEditing" />
  </VCard>
</template>

<script>
import PromotionsTable from '@/components/PromotionsTable.vue';
import PromotionEdit from '@/components/PromotionEdit.vue';
import { createPromotion, updatePromotion, getTargetList } from '@/api/api';
import { eventHub, events } from '@/global/eventhub';

export default {
  components: { PromotionEdit, PromotionsTable },
  data() {
    return {
      state: 'LIST',
      editedPromotion: null,
      targets: {},
    };
  },
  created() {
    this.fetchTargetList();
  },
  methods: {
    goToViewList() {
      this.state = 'LIST';
      this.editedPromotion = null;
    },
    goToCreating() {
      this.state = 'CREATING';
      this.editedPromotion = null;
    },
    goToEditing(promotion) {
      this.state = 'EDITING';
      this.editedPromotion = promotion;
      this.$nextTick().then(() => {
        window.scrollTo({
          top: 0,
          behavior: 'smooth',
        });
      });
    },
    async saveEditedPromotion(editedPromotion) {
      if (this.state === 'CREATING') {
        await createPromotion(editedPromotion.deserialized)
          .then((newPromotion) => {
            eventHub.$emit(events.promotionCreated, newPromotion);
          });
      } else if (this.state === 'EDITING') {
        await updatePromotion(this.editedPromotion.pk, editedPromotion.deserialized)
          .then((updatedPromotion) => {
            eventHub.$emit(events.promotionUpdated, updatedPromotion);
          });
      }
      this.goToViewList();
    },
    fetchTargetList() {
      getTargetList().then((result) => {
        result.forEach((target) => {
          this.targets[target.code] = target.title;
        });
      });
    },
  },
};
</script>

<style lang="scss" scoped>
@import "src/assets/scss/page.scss";

</style>
